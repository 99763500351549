.pulse_loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
}
.pulse_loader span {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #9880ff;
  -webkit-animation: pulse_loader 1.5s linear infinite;
  animation: pulse_loader 1.5s linear infinite;
}
.pulse_loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}
@keyframes pulse_loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes pulse_loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/* ================================================================ */

.dot-falling {
  position: relative;
  left: -9999px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
  left: -10px;
}
.dot-falling::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
  left: 10px;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
